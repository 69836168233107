// src/pages/Home.js
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons for features
import TouchAppIcon from '@mui/icons-material/TouchApp';
import InsightsIcon from '@mui/icons-material/Insights';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

// Image imports
import rcfLogo from '../assets/rcfLogo.png';  // Check path
import heroBg from '../assets/hero-bg.jpg';   // Check path

// ------------------------------
// Time-Based Greeting Logic
// ------------------------------
const hours = new Date().getHours();
let greeting;
if (hours < 12) {
  greeting = 'Good morning';
} else if (hours < 18) {
  greeting = 'Good afternoon';
} else {
  greeting = 'Good evening';
}

// ------------------------------
// Shared Animations
// ------------------------------
const fadeInKeyframes = {
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
};

// ------------------------------
// Styled Components
// ------------------------------
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '85vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  textAlign: 'center',
  padding: theme.spacing(14, 2),
  background: `
    linear-gradient(to right, rgba(33,150,243,0.7), rgba(30,136,229,0.7)),
    url(${heroBg})
  `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // Parallax-like effect on larger screens
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('md')]: {
    backgroundAttachment: 'scroll', // disable parallax on mobile
    padding: theme.spacing(10, 2),
  },
}));

const AnimatedOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: '-50%',
  width: '200%',
  height: '100%',
  background: 'radial-gradient(at 50% 50%, rgba(255,255,255,0.06), transparent 40%)',
  animation: 'floatAnimation 10s infinite linear',
  '@keyframes floatAnimation': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-10%)' },
    '100%': { transform: 'translateX(0)' },
  },
  pointerEvents: 'none',
  zIndex: 1,
}));

// Fade-in animation for Hero content
const HeroContent = styled(Box)(() => ({
  maxWidth: '800px',
  zIndex: 2,
  animation: 'fadeInHero 1.3s ease-in-out forwards',
  opacity: 0,
  '@keyframes fadeInHero': fadeInKeyframes,
}));

const RcfLogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  opacity: 0.15,
  filter: 'grayscale(100%)',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.4,
  },
}));

// Hover lift effect for feature cards
const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  minHeight: '240px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(2),
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  animation: 'fadeInFeatures 1.1s ease-in-out forwards',
  opacity: 0,
  '@keyframes fadeInFeatures': fadeInKeyframes,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[6],
  },
}));

// A stylized button with a subtle gradient
const GradientButton = styled(Button)(() => ({
  background: 'linear-gradient(to right, #2196f3, #0d47a1)',
  color: '#fff',
  textTransform: 'none',
  fontWeight: 'bold',
  minWidth: 180,
  '&:hover': {
    background: 'linear-gradient(to right, #0d47a1, #2196f3)',
  },
}));


// ------------------------------
// Main Component
// ------------------------------
const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Smooth scroll to Features section
  const scrollToFeatures = () => {
    const features = document.getElementById('features');
    if (features) {
      features.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <AnimatedOverlay />

        <HeroContent>
          <Typography
            variant="h5"
            component="p"
            sx={{
              fontWeight: 300,
              letterSpacing: 1,
              mb: 1,
              opacity: 0.9,
            }}
          >
            {greeting}
          </Typography>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 700,
              letterSpacing: '.5px',
              mb: 3,
              [theme.breakpoints.down('sm')]: {
                fontSize: '2rem',
              },
            }}
          >
            Welcome to the RCF
          </Typography>


          {/* Hero Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <GradientButton
              href="https://crm.orthinas.com"
              variant="contained"
            >
              Try Orthinas CRM
            </GradientButton>

            <Button
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                textTransform: 'none',
                fontWeight: 'bold',
                minWidth: 180,
                '&:hover': {
                  borderColor: '#fff',
                },
              }}
              onClick={scrollToFeatures}
            >
              Learn More
            </Button>
          </Box>
        </HeroContent>

        {/* Semi-transparent RCF logo on the corner */}
        <RcfLogoBox
          component="a"
          href="https://www.rcf-company-website.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={rcfLogo}
            alt="RCF Logo"
            sx={{ height: 50, width: 'auto' }}
          />
        </RcfLogoBox>
      </HeroSection>


      {/* Main Content Section */}
      <Container maxWidth="md" sx={{ py: 8 }}>
        {/* Features Section */}
        <Box id="features" sx={{ mb: 6 }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 700, mb: 6 }}
          >
            Why Choose Orthinas CRM?
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <FeatureCard elevation={4}>
                <TouchAppIcon sx={{ fontSize: 44, color: 'primary.main' }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', mb: 1 }}
                >
                  Intuitive Interface
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
                  Seamlessly integrate Orthinas tools into your workflow with
                  minimal training, thanks to a user-friendly design.
                </Typography>
              </FeatureCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <FeatureCard elevation={4}>
                <InsightsIcon sx={{ fontSize: 44, color: 'primary.main' }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', mb: 1 }}
                >
                  Data-Driven Insights
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
                  Leverage powerful analytics to make informed decisions,
                  improve processes, and stay ahead in a competitive market.
                </Typography>
              </FeatureCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <FeatureCard elevation={4}>
                <AutoAwesomeIcon sx={{ fontSize: 44, color: 'primary.main' }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', mb: 1 }}
                >
                  Future-Ready
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
                  Orthinas evolves with technology, ensuring your business
                  remains agile and prepared for tomorrow’s challenges.
                </Typography>
              </FeatureCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Home;
