// src/components/Footer.js
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Link,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

// ------------------------------
// Footer Container
// ------------------------------
const FooterContainer = styled(Box)(({ theme }) => ({
  // Enough top spacing so it won't overlap the wave
  marginTop: theme.spacing(8),
  background: 'linear-gradient(to right, #1976d2, #0D47A1)',
  color: theme.palette.common.white,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

// ------------------------------
// Main Footer Component
// ------------------------------
const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FooterContainer component="footer">
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              RCF
            </Typography>
            <Typography variant="body2" sx={{ maxWidth: 280, lineHeight: 1.7 }}>
              Empowering digital innovation through celestial-inspired solutions.
            </Typography>
            
        
        
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
              Quick Links
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.7 }}>
              <Link
                href="https://crm.orthinas.com"
                color="inherit"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                Orthinas CRM
              </Link>
              <br />
              {/* Add more links here */}
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
              Learn More
            </Typography>
            <Typography variant="body2" sx={{ maxWidth: 280, lineHeight: 1.7 }}>
              We&apos;re committed to powering the future of work 
              through advanced technology solutions.
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: 'rgba(255,255,255,0.3)' }} />

        <Typography variant="body2" color="inherit" textAlign="center">
          &copy; {new Date().getFullYear()} All rights reserved.
        </Typography>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
