// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0D47A1',  // Strong blue for branding
    },
    secondary: {
      main: '#00C853', // Vibrant green accent
    },
    background: {
      default: '#121212', // Dark base background
      paper: '#1E1E1E',   // Slightly lighter panels
    },
    text: {
      primary: '#ffffff',
      secondary: '#B0B0B0',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#29b6f6',
    },
    success: {
      main: '#66bb6a',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'Roboto, Inter, sans-serif',
    // Example of customizing heading styles
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      letterSpacing: '-0.02em',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.4rem',
      letterSpacing: '-0.02em',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    // You can continue customizing other headings if desired
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  // Example: override default component styles
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none', // Removes MUI's default paper gradient
        },
      },
    },
  },
});

export default theme;
