// src/components/Navigation.js

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.png'; // Adjust path as needed

// ------------------------------
// Styled Components
// ------------------------------
const LogoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
}));

// Navigation Links Container
const NavLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

// Single Link
const NavLinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.95rem',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Mobile Drawer State
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  // Dummy links for demonstration; replace with real routes
  const navItems = [
    { label: 'Orthinas CRM', to: 'http://crm.orthinas.com' },

  ];

  return (
    <>
      <AppBar position="fixed" elevation={2}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo Section */}
          <LogoBox component={Link} to="/">
            <Box
              component="img"
              src={logo}
              alt="Orthinas Logo"
              sx={{ height: 40, width: 'auto' }}
            />
          </LogoBox>

          {/* Desktop Links or Mobile Menu */}
          {isMobile ? (
            <>
              {/* Mobile Menu Icon */}
              <IconButton onClick={toggleDrawer} sx={{ color: '#fff' }}>
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            <NavLinks>
              {navItems.map((item) => (
                <NavLinkButton
                  key={item.label}
                  component={Link}
                  to={item.to}
                >
                  {item.label}
                </NavLinkButton>
              ))}
            </NavLinks>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <List sx={{ width: 220 }}>
          {navItems.map((item) => (
            <ListItem disablePadding key={item.label}>
              <ListItemButton
                component={Link}
                to={item.to}
                onClick={toggleDrawer}
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
